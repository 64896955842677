'use strict';

// import './modules/polyfill-foreach.js';
// import './modules/keyboard-mouse.js';
// import './modules/search-toggle.js';
import './modules/post-listing.js';


// Scroll to form confirmation message
document.addEventListener('DOMContentLoaded', function() {
    const confirmationMessage = document.querySelector('.gform_confirmation_message');
    if (confirmationMessage) {
        const yOffset = -160; // Offset by 160px to account for header
        const y = confirmationMessage.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'instant'});
    }
});

// Test class to test cache settings
const testClass = document.querySelector('.test-class-to-test-cache-settings');
if (testClass) {
    console.log('Test class found');
}
