// On load
document.addEventListener('DOMContentLoaded', function() {
    // DLF post listing
    const postEntries = document.querySelectorAll('.dlf-content .avia-content-slider .post-entry');

    function setHeight(entry) {
        // If screen is smaller than 768px, set height to auto
        if (window.innerWidth < 768) {
            entry.style.height = 'auto';
        } else {
            entry.style.height = `${entry.parentElement.clientHeight}px`;
        }
    }
    
    postEntries.forEach(entry => {

        // Move date stamp to the bottom of container
        const slideContent = entry.querySelector('.slide-content');
        const slideMeta = entry.querySelector('.slide-content .slide-meta');

        if (slideContent && slideMeta) {
            slideContent.removeChild(slideMeta);
            slideContent.appendChild(slideMeta);
        }

        // Set height as the same as parent
        setHeight(entry);

        window.addEventListener('resize', () => {
            setHeight(entry);
        });
    });
});


